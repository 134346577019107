import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Login from 'pages/Login';
import Setting from 'pages/Setting';

// render - sample page
const MeetingsPage = Loadable(lazy(() => import('pages/MeetingsPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <MeetingsPage />
      },
      {
        path: 'meetings',
        element: <MeetingsPage />
      },
      {
        path: 'settings',
        element: <Setting />
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  }
];

export default MainRoutes;
