import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';

import logo from 'assets/app_icon.jpeg';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <div
        style={{
          padding: 3,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: '70%',
            margin: 3
          }}
        />
      </div>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
