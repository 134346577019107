// assets
import { VideoCameraOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS - MEETING PAGE ||============================== //

const meeting = {
  id: 'meeting',
  title: 'Meeting',
  type: 'group',
  children: [
    {
      id: 'all-meetings',
      title: 'All Meetings',
      type: 'item',
      url: '/meetings',
      icon: VideoCameraOutlined
    }
  ]
};

export default meeting;
