import { Box, Grid, Typography } from '@mui/material';

// project import
import AuthUpdate from './AuthUpdate';
import AuthCard from './AuthCard';

// ================================|| LOGIN ||================================ //

const Setting = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <AuthCard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">Update Password</Typography>
        </Grid>
        <Grid item xs={12}>
          <AuthUpdate />
        </Grid>
      </Grid>
    </AuthCard>
  </Box>
);

export default Setting;
