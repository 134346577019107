// assets
import { SettingOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS - SETTING PAGE ||============================== //

const setting = {
  id: 'setting',
  title: 'Setting',
  type: 'group',
  children: [
    {
      id: 'all-setting',
      title: 'Setting',
      type: 'item',
      url: '/settings',
      icon: SettingOutlined
    }
  ]
};

export default setting;
