import React, { useEffect } from 'react';

// material-ui
import { Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';

// project import
import AnimateButton from 'components/@extended/AnimateButton';

import { useAuth } from 'context/AuthContext';
import { useNavigate } from 'react-router';

// ============================|| LOGIN ||============================ //

const AuthUpdate = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  return (
    <>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: ''
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().min(3, 'Password too short').required('Password is required'),
          newPassword: Yup.string().min(3, 'Password too short').required('Password is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            axios
              .post(process.env.REACT_APP_API_ENPOINT + '/users/update', {
                username: user.username,
                ...values
              })
              .then((res) => {
                if (res && res.data && res.data.success) {
                  setErrors({ submit: 'Password updated' });
                  setStatus({ success: true });
                } else {
                  setErrors({ submit: res.data.message });
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              })
              .catch(() => {
                setErrors({ submit: 'Something went wrong' });
                setStatus({ success: false });
                setSubmitting(false);
              });
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="oldPassword-login">Old Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    id="-oldPassword-login"
                    type="password"
                    value={values.oldPassword}
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Old Password"
                  />
                  {touched.oldPassword && errors.oldPassword && (
                    <FormHelperText error id="standard-weight-helper-text-oldPassword-login">
                      {errors.oldPassword}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="newPassword-login">New Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    id="-newPassword-login"
                    type="password"
                    value={values.newPassword}
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter New Password"
                  />
                  {touched.newPassword && errors.newPassword && (
                    <FormHelperText error id="standard-weight-helper-text-newPassword-login">
                      {errors.newPassword}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <AnimateButton>
                  <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                    Update
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthUpdate;
